import React from 'react';
import './Logo.css';
import logo from '../assets/logo.png'; 

function Logo() {
  return (
    <div className="logo-container">
      <img src={logo} alt="Authority Logo" className="logo" />
    </div>
  );
}

export default Logo;