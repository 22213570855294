import React, { useState } from 'react';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import InputFieldWithIcon from './InputFieldWithIcon';
import Button from './Button';
import Logo from './Logo'; // Import the Logo component
import './LoginForm.css';

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    // Handle login logic here
    console.log('Email:', email);
    console.log('Password:', password);
  };

  return (
    <div className="login-form-container">
      <form className="login-form" onSubmit={handleLogin}>
        <Logo />
        <h1>LibraLink</h1> {/* Add the heading here */}
        <h2>Welcome Back!</h2>
        <p>Please login to your account</p>
        <InputFieldWithIcon
          icon={faUser}
          type="text"
          placeholder="User ID"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputFieldWithIcon
          icon={faLock}
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button text="Login" />
      </form>
    </div>
  );
}

export default LoginForm;