import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './InputFieldWithIcon.css';

function InputFieldWithIcon({ icon, type, placeholder, value, onChange }) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="input-field-with-icon">
      <FontAwesomeIcon icon={icon} className="icon" />
      <input
        type={isPasswordVisible ? 'text' : type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {type === 'password' && (
        <FontAwesomeIcon
          icon={isPasswordVisible ? faEyeSlash : faEye}
          className="toggle-password-icon"
          onClick={togglePasswordVisibility}
        />
      )}
    </div>
  );
}

export default InputFieldWithIcon;